import { Cookies } from "react-cookie";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
export const cookies = new Cookies();

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MAX_PASSWORD_LENGTH = 12;
export const MIN_PASSWORD_LENGTH = 8;
export const MOBILE_REGEX = /^[6-9]\d{9}$/;

export const handleLoginRedirect = (data) => {
  if (data) {
    const responseData = data.data;
    if (responseData.jwt) {
      cookies.set("jwt", responseData.jwt, { path: "/", maxAge: 43200 });
    }
    if (responseData.name) {
      cookies.set("name", responseData.name, { path: "/", maxAge: 43200 });
    }
    if (responseData.email) {
      cookies.set("email", responseData.email, { path: "/", maxAge: 43200 });
    }
    if (responseData.is_nabard_exclusive) {
      cookies.set("is_nabard_exclusive", responseData.is_nabard_exclusive, { path: "/", maxAge: 43200 });
    }
    if (responseData) {
      cookies.set("is_admin", responseData.is_admin, {
        path: "/",
        maxAge: 43200,
      });
    }
  }
  return true;
};

// function that checks the admin is organizationAdmin
export const isAdmin = () => {
  const admin = cookies.get("is_admin", { path: "/" });
  if (admin) {
    if (admin != "false") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
  // if (admin) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const getCockieData = () => {
  const cockieData = {};
  document.cookie.split(';').forEach(item => {
    const data = item.split('=').map(d => d.trim());
    if (data[1]) {
      data[1] = decodeURI(data[1]);
    }
    if (data[1] === 'true') {
      data[1] = true;
    }
    if (data[1] === 'false') {
      data[1] = false;
    }
    cockieData[data[0]] =  data[1];
  });
  return cockieData;
}

// export const AXIOS_INSTANCE = axios.create({
//   headers: {
//     "Content-Type": "application/json",
//      Authorization: cookies.get("jwt", { path: "/" }),
//   },
// });

export const isFpoIdPresent = () => {
  if (cookies.get("fpoId", { path: "/" })) {
    return true;
  } else {
    return false;
  }
};

export const getLoggedInUser = () => {
  const token = cookies.get("jwt", { path: "/" });
  return token;
};
export const isUserAuthenticated = () => {
  const isUserLoggedIn = getLoggedInUser();
  if (isUserLoggedIn) {
    return true;
  } else {
    return false;
  }
};

export const clearCookies = () => {
  cookies.remove("jwt", { path: "/" });
  cookies.remove("fpoId", { path: "/" });
  cookies.remove("name", { path: "/" });
  cookies.remove("email", { path: "/" });
  cookies.remove("is_admin", { path: "/" });
  cookies.remove("is_nabard_exclusive", { path: "/" });
  cookies.remove("captcha_wedgit", { path: "/" });
};

// Function to get reverse date format
export const reverseDateFormat = (date) => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate).local().format("YYYY-MM-DD");
  return formatedDate;
};

export const localDenominateSign = (numString) => {
  if (numString) {
    let numeric = parseInt(numString);
    let absNumeric = Math.abs(numeric);
    let absStringLength = absNumeric.toString().length;
    let parsedDenomination;
    switch (true) {
      case absStringLength >= 8:
        parsedDenomination = `${(absNumeric / 10000000).toFixed(1)} Cr`;
        if (numeric >= 0) return parsedDenomination;
        else return `-${parsedDenomination}`;
      case absStringLength == 6 || absStringLength == 7:
        parsedDenomination = `${(absNumeric / 100000).toFixed(1)} L`;
        if (numeric >= 0) return parsedDenomination;
        else return `-${parsedDenomination}`;
      case absStringLength == 5:
        parsedDenomination = `${absNumeric.toString().slice(0, 2)},${absNumeric
          .toString()
          .slice(2, 5)}`;
        if (numeric >= 0) return parsedDenomination;
        else return `-${parsedDenomination}`;
      case absStringLength == 4:
        parsedDenomination = `${absNumeric.toString().slice(0, 1)},${absNumeric
          .toString()
          .slice(1, 4)}`;
        if (numeric >= 0) return parsedDenomination;
        else return `-${parsedDenomination}`;
      default:
        return numeric;
    }
  } else return 0;
};


export const view = [1000, 150]; // ViewBox: Width, Height
export const trbl = [20, 50, 20, 50]; // Margins: Top, Right, Bottom, Left

export const dims = [
  view[0] - trbl[1] - trbl[3], // Adjusted dimensions width
  view[1] - trbl[0] - trbl[2] // Adjusted dimensions height
];


/*function for get ordered(asc) array items*/
export const getOrderedItems = (rawArray, order, conditionName) => {
  if (conditionName) {
    return _.orderBy(rawArray, [conditionName], [order]); // Use Lodash to sort array by 'condition'
  } else {
    return _.orderBy(rawArray, [(item) => item], [order]); // Use Lodash to sort array by 'Label'
  }
};

export const getAlphaBeticalOrderItems = (rawArray, order, conditionName) => {
    return _.orderBy(rawArray, [(item) => item[`${conditionName}`].toLowerCase()], [order]); // Use Lodash to sort array by 'Label'
  }

export const updateTitle = (text) => document.querySelector('head').querySelector('title').innerText = `FPO Platform | ${text}`;

export const debounce = (func, waits) => {
  return _.debounce(func, waits);
}
