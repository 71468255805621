import './App.css';
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

import { Cookies } from "react-cookie";
import { HelmetProvider } from 'react-helmet-async';


const Header =  lazy(() => import('./component/Header/Header'));
const Home =  lazy(() => import('./component/Home'));
const Login =  lazy(() => import('./component/Login'));
const EmailVerify =  lazy(() => import('./component/EmailVerify'));
const Register =  lazy(() => import('./component/Register'));
const ForgotPassword =  lazy(() => import('./component/ForgotPassword'));
const ResetPassword =  lazy(() => import('./component/ResetPassword'));
const FpoMap =  lazy(() => import('./component/FpoMap'));
const MyUsage =  lazy(() => import('./component/MyUsage'));
const MyInbox =  lazy(() => import('./component/MyInbox'));
const Search =  lazy(() => import('./component/Search'));
const MyAccount =  lazy(() => import('./component/MyAccount'));
const FpoView =  lazy(() => import('./component/FpoView'));
const Dashboard =  lazy(() => import('./component/Dashboard'));
const FullInfo =  lazy(() => import('./component/FullInfo'));
const MoreInfo =  lazy(() => import('./component/MoreInfo'));
const ChartComingSoon =  lazy(() => import('./component/ChatComingSoon'));
const ComingSoon =  lazy(() => import('./component/ComingSoon'));
const TermsOfUse =  lazy(() => import('./component/TermsOfUse'));
const MoreInfoClaimedFpo =  lazy(() => import('./component/MoreInfoClaimedFpo'));
const NewDashboardTableView =  lazy(() => import('./component/NewDashboardTableView'));
const FpoMoreInfo =  lazy(() => import('./component/FpoMoreInfo'));
const Summary =  lazy(() => import('./component/Summary'));
const UnlockFpo =  lazy(() => import('./component/UnlockFpo'));
const ToClaimFpoAfterLogin =  lazy(() => import('./component/ToClaimFpoAfterLogin'));
const SecondFpoView =  lazy(() => import('./component/SecondFpoView'));
const MoreInfoUnclaimedwithoutLogin =  lazy(() => import('./component/MoreInfoUnclaimedwithoutLogin'));
const RequestInbox =  lazy(() => import('./component/RequestInbox'));
const HomePageMap =  lazy(() => import('./component/HomePageMap'));
const NabardFullInfo =  lazy(() => import('./component/NabardFullInfo'));
const NabardDetails =  lazy(() => import('./component/NabardDetails'));
const Filters =  lazy(() => import('./component/Filters'));
const Feedback =  lazy(() => import('./component/Feedback'));
const FeedbackView =  lazy(() => import('./component/FeedbackView'));
const ViewAccessRequests =  lazy(() => import('./component/ViewAccessRequests'));
const UserManagement =  lazy(() => import('./component/UserManagement'));
const ScrollToTop =  lazy(() => import('./component/ScrollToTop'));
const AdminApprovedTable =  lazy(() => import('./component/AdminApprovedTable'));
const ManageSurvey =  lazy(() => import('./component/ManageSurvey'));
const EditClaimFPO =  lazy(() => import('./component/EditClaimFPO'));
const ManageClaimFPO =  lazy(() => import('./component/ManageClaimFPO'));
const FpoClaimManagement =  lazy(() => import('./component/FpoClaimManagement'));
const FpoClaimManagementRequest =  lazy(() => import('./component/FpoClaimManagementRequest'));
const SendQuery =  lazy(() => import('./component/SendQuery'));
const FpoClaimManagementQuery =  lazy(() => import('./component/FpoClaimManagementQuery'));
const FpoClaimAdminFullDetails =  lazy(() => import('./component/FpoClaimAdminFullDetails'));
const ManageFpos =  lazy(() => import('./component/ManageFpos'));
const EditManageSurvey =  lazy(() => import('./component/EditManageSurvey'));
const NabardUserInbox =  lazy(() => import('./component/NabardUserInbox'));
const SurveyDetails =  lazy(() => import('./component/SurveyDetails'));
const NabardManageUserServey =  lazy(() => import('./component/NabardManageUserServey'));
const ErrorPage =  lazy(() => import('./component/ErrorPage'));
const MetaTags =  lazy(() => import('./component/MetaTags'));
const EditMetaData =  lazy(() => import('./component/EditMetaData'));
const ViewNotificationHistory =  lazy(() => import('./component/ViewNotificationHistory'));




function App() {
  const cookies = new Cookies();
  const [isNabardUser, setNabardUser] = useState(cookies.get("is_nabard_exclusive", { path: "/" }))
  const [isAdmin, setAdmin] = useState(cookies.get("is_admin", { path: "/" }) && cookies.get("is_admin", { path: "/" }) === 'true' ? true : false)
  const [showHeader, setShowHeader] = useState(true);
  const [userUpdated, setuserUpdated] = useState(0);
  const [notificationNo, setNotificationNo] = useState(0);
  // const refreshHeader = () => {
  //   setAdmin(cookies.get("is_admin", { path: "/" }) && cookies.get("is_admin", { path: "/" }) === 'true' ? true : false)
  //   // setShowHeader(false);
  //   // setTimeout(setShowHeader(true), 2000);
  //   setuserUpdated(userUpdated+1);

  // }

  const updateNotificationNo = (num) => setNotificationNo(num);


  return (
    <>
    <HelmetProvider>
      <BrowserRouter>
      <Suspense>
      <MetaTags/>
       { showHeader ? <Header/> : ''}
        <ScrollToTop>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/verify-email" element={<EmailVerify />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/my-usage" element={<MyUsage />} />
            <Route path="/my-inbox" element={<MyInbox />} />
            <Route path="/search" element={<Search />} />
            {/* <Route path="/comingsoon" element={<Search />}/> */}
            <Route path="/request-inbox" element={<RequestInbox />} />
            <Route path="/fpo-view" element={<FpoView />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/nabard" element={<Dashboard />} />
            <Route path="/table-view" element={<NewDashboardTableView />} />
            <Route path="/more-info" element={<MoreInfo />} />
            <Route path="/home-page-map" element={<HomePageMap />} />
            {/* <Route path="/fpo-table-view" element={<FpoTableView />}/> */}
            {/* <Route path="/dashboard-table-view" element={<DashboardTableView />}/> */}
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            {/* <Route path="/new-dashboard" element={<NewDashboard />}/> */}
            <Route path="/more-info-claimed-fpo" element={<MoreInfoClaimedFpo />} />
            <Route path="/more-info-unclaimed-fpo" element={<MoreInfoUnclaimedwithoutLogin />} />
            <Route path="/unlock-fpo" element={<UnlockFpo />} />
            <Route path="/full-info/:state_name/:district_name/:fpo_name" element={<FullInfo />} />
            <Route path="/nabard-dashboard" element={<NabardFullInfo />} />
            <Route path="/nabard-details" element={<NabardDetails />} />
            <Route path="/survey-details" element={<SurveyDetails />} />
            {/* <Route path="/survey-details" element={<NabardSurveyDetails />}/> */}
            {/* <Route path="/manage-survey" element={<ManageSurvey />} /> */}
            <Route path="/manage-fpos" element={<ManageFpos />} />
            {/* <Route path="/edit-meta-data" element={<EditMetaData />} /> */}
            <Route path="/nabard-user-inbox" element={<NabardUserInbox />} />
            <Route path="/nabard-manage-user-survey" element={<NabardManageUserServey />} />
            <Route path="/edit-manage-survey" element={<EditManageSurvey />} />
            {/* */}
            <Route path="/fpoclaim-management-admin" element={<FpoClaimManagement />} />
            <Route path="/fpoclaim-management-viewmore" element={<FpoClaimManagementRequest/>} />
            <Route path="/sendquery" element={<SendQuery/>} />
            <Route path="/fpoclaim-management-query" element={<FpoClaimManagementQuery />} />
            <Route path="/fpoclaim-admin-full-info" element={<FpoClaimAdminFullDetails />} />
            <Route path="/edit-claim-fpo" element={<EditClaimFPO />} />
            <Route path="/manage-claim-fpo" element={<ManageClaimFPO />} />
            {/* <Route path="/manage-fpos" element={<ManageFpoUsers />}/> */}
            <Route path="/claim-fpo-after-login" element={<ToClaimFpoAfterLogin />} />
            <Route path="/fpo-more-info" element={<FpoMoreInfo />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/second-fpo-view" element={<SecondFpoView />} />
            <Route path="/fpo-map" element={<FpoMap />} />
            <Route path="/filters" element={<Filters />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/view-feedback" element={<FeedbackView />} />
            <Route path="/view-feedback/:id" element={<FeedbackView />} />
            <Route path="/view-access-requests" element={<ViewAccessRequests />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/admin-table" element={<AdminApprovedTable />} />
            <Route path="/view-notification-history" element={<ViewNotificationHistory />} />
            <Route path="/coming-soon" element={<ChartComingSoon />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ScrollToTop>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
    </>
  );
}

export default App;
