import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const NotificationSlice = createSlice({
    name: 'notification_number',
    initialState,
    reducers: {
        setNotificationNumber: (state, action) => {
            state = action.payload || '';
            return state;
        },
    }
});

export const { setNotificationNumber  } = NotificationSlice.actions;

export default NotificationSlice.reducer;