import { createSlice } from '@reduxjs/toolkit';

import {getCockieData} from "../../constant/helpers";


const initialState = getCockieData() || {};

const UserSessionSlice = createSlice({
    name: 'user_session',
    initialState,
    reducers: {
        addSession: (state, action) => {
            state = action.payload || {};
            return state;
        },
        removeSession: (state) => {
            state = getCockieData() || {};
            return state;
        }
    }
});

export const { addSession, removeSession } = UserSessionSlice.actions;

export default UserSessionSlice.reducer;