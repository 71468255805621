import { configureStore } from "@reduxjs/toolkit";
import UserSessionSlice from "./slice/UserSessionSlice";
import NotificationSlice from "./slice/NotificationSlice";
const store = configureStore({
    reducer: {
         session: UserSessionSlice,
         notificaton_number: NotificationSlice
    },
});

export default store;